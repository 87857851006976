import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { finalize, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { AppService } from 'src/app/shared/services/app.service';
import { AuthService } from '../../services/auth.service';
import { ThomsonUserProfile } from 'src/app/shared/enums/thomson-user-profile';
import { PartnerUserProfile } from 'src/app/shared/enums/partner-user-profile';
import { ModalComponent } from 'ngx-chameleon';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {

  constructor(
    private router: Router,
    private appService: AppService,
    private authService: AuthService) {
    super();
  }

  submited = false;
  currentBackground = '';

  form = new FormGroup({
    email: new FormControl('', Validators.compose([
      Validators.required,
      Validators.email
    ])),
    password: new FormControl('', Validators.required)
  });

  ngOnInit() {
    this.rotateBackgroundImages();
  }

  signIn() {
    this.submited = true;
    if (this.form.invalid) { return; }

    this.appService.spinner.show();

    this.authService.signin(this.form.value).pipe(
      takeUntil(this.destroy$),
      finalize(() => this.appService.spinner.hide())
    ).subscribe(resp => {
      this.appService.storeAccessToken(resp.data.accessToken);
      this.appService.storeUser(resp.data.user);
      this.router.navigate(['/home']);
    });
  }

  hasThomsonProfile(user, ...profiles: ThomsonUserProfile[]) {
    for (let p in profiles){
      if (user.profile & profiles[p]){
        return true;
      }
    }
    return false;
  }

  hasPartnerProfile(user, ...profiles: PartnerUserProfile[]) {
    for (let p in profiles){
      if (user.profile & profiles[p]){
        return true;
      }
    }
    return false;
  }

  rotateBackgroundImages() {
    var randomNumber = Math.floor(Math.random() * 6) + 1;

    this.currentBackground = 'bg_'+randomNumber+'.jpg';

    setTimeout(() => {
      this.rotateBackgroundImages();
    }, 10000);
  }
}
