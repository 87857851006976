import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModel } from '@angular/forms';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MainRoutingModule } from './main-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MainComponent } from './main.component';
import { AppHttpInterceptor } from 'src/app/shared/app-http.interceptor';
import { LoginComponent } from './components/login/login.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { MenuHomeComponent } from './components/menu-home/menu-home.component';
import { HomeComponent } from './components/home/home.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RecoverPasswordWithTokenComponent } from './components/recover-password-with-token/recover-password-with-token.component';
import { AppConfigService } from '../shared/services/app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

export function init_app(configService: AppConfigService) {
  return () => configService.load();
}

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('pt');
    return firstValueFrom(translate.use('pt'));
  };
}

@NgModule({
  declarations: [
    MainComponent,
    LoginComponent,
    HomeComponent,
    ForbiddenComponent,
    MenuHomeComponent,
    ResetPasswordComponent,
    RecoverPasswordWithTokenComponent
  ],
  imports: [
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    MainRoutingModule
  ],
  providers: [
    NgModel,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [ AppConfigService ],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    }
  ],
  bootstrap: [MainComponent]
})
export class MainModule { }
