import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { AppConfig } from 'src/app/shared/models/app-config';
import { SessionUser } from 'src/app/shared/models/session-user';
import { AppConfigService } from 'src/app/shared/services/app-config.service';
import { AppService } from 'src/app/shared/services/app.service';


declare global { interface Window { Calendly: any; } }

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseComponent implements OnInit {

  constructor(
    private appConfigService: AppConfigService,
    private appService: AppService,
    private sanitizer: DomSanitizer) {
    super();
  }

  appConfig: AppConfig = this.appConfigService.appConfig;
  canBackoffice = false;
  canProject = false;
  canReport = false;
  canGed = false;
  canCertifications = false;
  year = new Date().getFullYear();

  user: SessionUser;

  slideIndex = 1;

  routeFolderId: string;
  routeMktFolderId: string;
  routeTrainingFolderId: string;
  routeToolsFolderId: string;
  routeSalesFolderId : string;
  routeCorporateSolutionFolderId : string;
  ngOnInit() {

    this.user = this.appService.sessionUser;
    this.routeFolderId = '/ged/documents/' + this.appConfig.importantToKnowFolderId;
    this.routeMktFolderId = '/ged/documents/' + this.appConfig.marketingFolderId;
    this.routeTrainingFolderId = '/ged/documents/' + this.appConfig.trainigFolderId;
    this.routeToolsFolderId = '/ged/documents/' + this.appConfig.toolsFolderId;
    this.routeSalesFolderId = '/ged/documents/' + this.appConfig.salesPlayFolderId;
    this.routeCorporateSolutionFolderId = '/ged/documents/' + this.appConfig.corporateSolutionFolderId;
    this.canBackoffice = this.appService.canBackoffice(this.user);
    this.canProject = this.appService.canProject(this.user);
    this.canReport = this.appService.canReport(this.user);
    this.canGed = this.appService.canGed(this.user);
    this.canCertifications = this.appService.canCertifications(this.user);

    this.showSlides(this.slideIndex);
  }

  // Next/previous controls
  plusSlides(n) {
    this.showSlides(this.slideIndex += n);
  }

  // Thumbnail image controls
  currentSlide(n) {
    this.showSlides(this.slideIndex = n);
  }

  showSlides(index) {
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");

    if (index > slides.length) this.slideIndex = 1;
    if (index < 1) this.slideIndex = slides.length;

    for (let i = 0; i < slides.length; i++) {
      slides[i].setAttribute('style', 'display: none');
      dots[i].className = dots[i].className.replace(" active", "");
    }

    slides[this.slideIndex - 1].setAttribute('style', 'display: block');
    dots[this.slideIndex - 1].className += " active";
  }

  videoURL(url): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
