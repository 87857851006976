<div class="ch-loader-progress"></div>
<div ch-side-bar app-name="" logo-url="assets/images/ch-logo.png">

  <ch-side-bar-menu>
    <!--Home-->
    <ch-side-bar-link [route]="'/home'" [exact-link]="true" icon="fal fa-home fa-icon"
      [description]="'modules.main.components.menu.home' | translate">
    </ch-side-bar-link>

    <!--GED-->
    <ch-side-bar-link *ngIf="canGed" [route]="['/ged/documents']" icon="fal fa-folders fa-fw fa-icon"
      [description]="'modules.ged.title'| translate">
    </ch-side-bar-link>

    <!--Projects-->
    <!-- <ch-side-bar-link *ngIf="canProject" [route]="['/projects/panel']" [has-children]="true"
      icon="fal fa-spinner fa-icon" [description]="'modules.projects.title'| translate">

      <ch-side-bar-link [route]="['/projects/panel']" icon="fal fa-list fa-icon"
        [description]="'modules.projects.components.panel.title' | translate">
      </ch-side-bar-link>

      <ch-side-bar-link [route]="['/projects/report']" icon="fal fa-file-spreadsheet fa-icon"
        [description]="'modules.projects.components.reports.title' | translate">
      </ch-side-bar-link>

      <ch-side-bar-link [route]="['/projects/solutionsReport']" icon="fal fa-file-spreadsheet fa-icon"
        [description]="'modules.projects.components.reports.solutionReport.title' | translate">
      </ch-side-bar-link>

      <ch-side-bar-link *ngIf="isPartner()" [route]="['/projects/customers']" icon="fal fa-users fa-icon"
        [description]="'modules.backoffice.components.customer.title' | translate">
      </ch-side-bar-link>

    </ch-side-bar-link> -->

    <!--Backoffice-->
    <ch-side-bar-link *ngIf="canBackoffice" [has-children]="true" icon="fal fa-building fa-icon"
      [description]="'modules.backoffice.title'| translate">

      <ch-side-bar-link [route]="['/backoffice/partners']" icon="fal fa-user-tie fa-icon"
        [description]="'modules.backoffice.components.partner.title' | translate">
      </ch-side-bar-link>

      <!-- <ch-side-bar-link [route]="['/backoffice/customers']" icon="fal fa-users fa-icon"
        [description]="'modules.backoffice.components.customer.title' | translate">
      </ch-side-bar-link> -->

      <ch-side-bar-link [route]="['/backoffice/users']" icon="fal fa-user fa-icon"
        [description]="'modules.backoffice.components.users.title'| translate">
      </ch-side-bar-link>

      <!-- <ch-side-bar-link [route]="['/backoffice/segments']" icon="fal fa-address-card fa-icon"
        [description]="'modules.backoffice.components.segments.title' | translate">
      </ch-side-bar-link> -->

      <!-- <ch-side-bar-link [route]="['/backoffice/roles']" icon="fal fa-user-cog fa-icon"
        [description]="'modules.backoffice.components.roles.title' | translate">
      </ch-side-bar-link> -->

      <!-- <ch-side-bar-link [route]="['/backoffice/solutions']" icon="fal fa-laptop fa-icon"
        [description]="'modules.backoffice.components.solutions.title'|translate">
      </ch-side-bar-link> -->

      <!-- <ch-side-bar-link [route]="['/backoffice/erps']" icon="fal  fa-project-diagram fa-icon"
        [description]="'modules.backoffice.components.erps.title'|translate">
      </ch-side-bar-link> -->

      <!-- <ch-side-bar-link [route]="['/backoffice/connection-types']" icon="fal  fa-bezier-curve fa-icon"
        [description]="'modules.backoffice.components.connectionTypes.title'|translate">
      </ch-side-bar-link> -->

      <!-- <ch-side-bar-link [route]="['/backoffice/countries']" icon="fal  fa-globe-americas fa-icon"
        [description]="'modules.backoffice.components.countries.title'|translate">
      </ch-side-bar-link>

      <ch-side-bar-link [route]="['/backoffice/states']" icon="fal  fa-map fa-icon"
        [description]="'modules.backoffice.components.states.title'|translate">
      </ch-side-bar-link>

      <ch-side-bar-link [route]="['/backoffice/cities']" icon="fal  fa-map-marker-alt fa-icon"
        [description]="'modules.backoffice.components.cities.title'|translate">
      </ch-side-bar-link> -->
    </ch-side-bar-link>

    <!-- Reports -->
    <ch-side-bar-link *ngIf="canReport" [has-children]="true" icon="fal fa-file-spreadsheet fa-icon"
      [description]="'modules.reports.title'| translate">

      <ch-side-bar-link [route]="['/reports/users']" icon="fal fa-user fa-icon"
        [description]="'modules.reports.components.title' | translate">
      </ch-side-bar-link>
    </ch-side-bar-link>

    <!--Trainings-->
    <li class="ch-menu-item" *ngIf="canGed">
      <a [href]="routeTrainingFolderId">
        <i class="fal fa-file-certificate fa-icon"></i>
        <span class="ch-menu-item-label">
          {{'modules.trainings.title' | translate}}
        </span>
      </a>
    </li>

    <!--Certifications-->
    <!-- <ch-side-bar-link *ngIf="canCertifications" [route]="['/certifications/consolidated']" [has-children]="true"
      icon="fal fa-certificate fa-icon" [description]="'modules.certificates.title'| translate">

      <ch-side-bar-link [route]="['/certifications/consolidated']" icon="fal fa-file-spreadsheet fa-icon"
        [description]="'modules.certificates.reports.consolidated.title' | translate">
      </ch-side-bar-link>
      <ch-side-bar-link [route]="['/certifications/expired']" icon="fal fa-file-spreadsheet fa-icon"
        [description]="'modules.certificates.reports.expired.title' | translate">
      </ch-side-bar-link>
      <ch-side-bar-link [route]="['/certifications/overview']" icon="fal fa-file-spreadsheet fa-icon"
        [description]="'modules.certificates.reports.overview.title' | translate">
      </ch-side-bar-link>
    </ch-side-bar-link> -->

    <!--Channel's Program-->
    <!-- <li class="ch-menu-item" *ngIf="canGed">
      <a [href]="routeChannelFolderId">
        <i class="fal fa-comments-dollar fa-icon"></i>
        <span class="ch-menu-item-label">
          {{'common.channel' | translate}}
        </span>
      </a>
    </li> -->

    <!--Marketing-->
    <li class="ch-menu-item" *ngIf="canGed">
      <a [href]="routeMktFolderId">
        <i class="fal fa-bullhorn fa-icon"></i>
        <span class="ch-menu-item-label">
          {{'modules.main.components.home.marketing' | translate}}
        </span>
      </a>
    </li>

    <!--Tools-->
    <li class="ch-menu-item" *ngIf="canGed">
      <a [href]="routeToolsFolderId">
        <i class="fal fa-toolbox fa-icon"></i>
        <span class="ch-menu-item-label">
          {{'common.tools' | translate}}
        </span>
      </a>
    </li>

    <!--Corporate Solutions-->
    <li class="ch-menu-item" *ngIf="canGed">
      <a [href]="routeCorporateSolutionFolderId">
        <i class="fal fa-briefcase fa-icon"></i>
        <span class="ch-menu-item-label">
          {{'common.corporateSolutions' | translate}}
        </span>
      </a>
    </li>
    <!--Sales Play-->
    <!-- <li class="ch-menu-item" *ngIf="canGed">
      <a [href]="routeSalesFolderId">
        <i class="fal fa-search-dollar fa-icon"></i>
        <span class="ch-menu-item-label">
          {{'common.salesPlay' | translate}}
        </span>
      </a>
    </li> -->

    <!--SFDC-->
    <!-- <li class="ch-menu-item" *ngIf="canGed">
      <a href="https://renewals-thomsonreuters.force.com/LatAmPartnerCommunity/login" target="_blank">
        <i class="fal fa-people-carry fa-icon"></i>
        <span class="ch-menu-item-label">
          {{'modules.sfdc.title' | translate}}
        </span>
      </a>
    </li> -->

    <!--User Guide-->
    <li class="ch-menu-item">
      <a href="/assets/files/Manual_Usuario_TR_PartnerPortal_v1.0.pdf" target="_blank">
        <i class="fal fa-info-circle fa-icon"></i>
        <span class="ch-menu-item-label">
          {{'common.guide' | translate}}
        </span>
      </a>
    </li>


    <ng-content></ng-content>

  </ch-side-bar-menu>

</div>

<div class="ch-content">
  <header ch-top-bar>
    <ch-top-bar-menu>

      <div *ngIf="user">
        {{ 'common.title.welcome' | translate }}, {{ user.firstName }}
      </div>
      <div class="ch-header-nav-button ch-dropdown ch-user unprintable" href="#" *ngIf="user">
        <button class="ch-avatar ch-dropdown-toggle" type="button">
          <img class="ui avatar image" [src]="avatar">
        </button>
        <div class="ch-dropdown-content bottom right" tabindex="-1">
          <div class="ch-user-container">
            <div class="ch-user-header">
              <img class="avatar" [src]="avatar">
              <div class="info">
                <strong class="name">{{user?.fullName}}</strong>
                <div class="email">{{user?.email}}</div>
              </div>
            </div>

            <div class="ch-user-action">
              <a (click)="myProfile()" target="_blank">
                <i class="fas fa-user-cog fa-icon"></i>
                <span class="label">{{ 'modules.shared.components.topMenu.userProfile' | translate }}</span>
              </a>
            </div>
            <div class="ch-user-action">
              <a noAction (click)="signout()">
                <i class="fal fa-sign-out fa-icon"></i>
                <span class="label">{{ 'modules.shared.components.topMenu.signout' | translate }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>

    </ch-top-bar-menu>
  </header>

  <div class="ui padded grid stackable">
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
