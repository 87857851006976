<div
  class="login-wrapper"
  [style]="
    'background-image: url(\'assets/images/' + currentBackground + '\');'
  "
>
<div class="ui middle aligned center aligned grid">
    <div class="column">
      <form class="ui large form">
        <div class="ui stacked segment" [formGroup]="form">
          <div class="field p-l-50 p-r-50 p-t-30 p-b-10">
            <img src="assets/images/ch-logo.png" class="logo" />
          </div>
          <ng-container *ngIf="result?.success">
            <div class="ui green message" *ngIf="result?.message">
              <div class="header">
                {{result?.message}}
              </div>
            </div>
            <div class="ui fluid thomson large primary submit button m-t-20" [routerLink]="['/']" >
              Login
            </div>
          </ng-container>
          <ng-container *ngIf="!result?.success">
            <h1 class="ui grey header">{{'common.passwordReset'| translate}}</h1>
            
            <div
                class="field required p-l-50 p-r-50"
                [ngClass]="{ error: form.controls.email.invalid && submited }"
            >
                <div class="ui left icon input">
                <i class="mail icon"></i>
                <input
                    type="text"
                    required
                    formControlName="email"
                    [placeholder]="
                    'modules.main.components.login.fields.email' | translate
                    "
                />
                </div>
                <ch-pointing-label
                *ngIf="submited && form.controls.email?.errors?.required"
                >
                {{
                    "modules.main.components.login.validations.email.required"
                    | translate
                }}
                </ch-pointing-label>
                <ch-pointing-label
                class="error"
                *ngIf="submited && form.controls.email?.errors?.email"
                >
                {{
                    "modules.main.components.login.validations.email.invalid"
                    | translate
                }}
                </ch-pointing-label>
            </div>
            <div class="field p-l-50 p-r-50">
                <button class="ui fluid large thomson submit button" (click)="send()">
                {{ "common.buttons.send" | translate }}
                </button>
            </div>
            <div class="field p-l-50 p-r-50 p-t-30 p-b-30">
              <a [routerLink]="['/login']">{{ 'common.buttons.login' | translate }}</a>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</div>
    
