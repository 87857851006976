<div
  class="login-wrapper"
  [style]="
    'background-image: url(\'assets/images/' + currentBackground + '\');'
  "
>
<div class="ui middle aligned center aligned grid">
    <div class="column">
      <form class="ui large form">
        <div class="ui stacked segment" [formGroup]="form">
          <div class="field p-l-50 p-r-50 p-t-30 p-b-10">
            <img src="assets/images/ch-logo.png" class="logo" />
          </div>
          <ng-container *ngIf="result?.success">
            <div class="ui green message" *ngIf="result?.message">
              <div class="header">
                {{result?.message}}
              </div>
            </div>
            <div class="ui fluid thomson large primary submit button m-t-20" [routerLink]="['/']" >
              Login
            </div>
          </ng-container>
          <ng-container *ngIf="!result?.success">
            <h3 class="ui grey header m-b-20">Insira os dados abaixo!</h3>
            <div class="field" [ngClass]="{'error': submited && form.controls.password.invalid}">
              <div class="ui left icon input">
                <i class="key icon"></i>
                <input type="password" [placeholder]="'modules.shared.components.profile.newPassword'|translate" formControlName="password">
              </div>
              <ch-pointing-label *ngIf="submited && form.controls.password?.errors?.required">
                {{'modules.shared.components.profile.newPasswordRequired' | translate}}
              </ch-pointing-label>
            </div>
            <div class="field" [ngClass]="{'error': submited && form.controls.passwordConfirmation.invalid}">
              <div class="ui left icon input">
                <i class="key icon"></i>
                <input type="password" [placeholder]="'modules.shared.components.profile.newPasswordConfirmed'|translate" formControlName="passwordConfirmation">
              </div>
              <ch-pointing-label *ngIf="submited && form.controls.passwordConfirmation?.errors?.required">
                {{'modules.shared.components.profile.newPasswordConfirmedRequired' | translate}}
              </ch-pointing-label>
              <ch-pointing-label *ngIf="submited && form.controls.passwordConfirmation?.errors?.match">
               {{'modules.shared.components.profile.newPasswordNotMatch' | translate}}
              </ch-pointing-label>
            </div>
            <div class="ui huge center thomson button m-t-20" (click)="send()">
              Confirmar <i class="right arrow icon"></i>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</div>
