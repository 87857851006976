import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { PartnerUserProfile } from 'src/app/shared/enums/partner-user-profile';
import { ThomsonUserProfile } from 'src/app/shared/enums/thomson-user-profile';
import { SessionUser } from 'src/app/shared/models/session-user';
import { AppService } from 'src/app/shared/services/app.service';

@Component({
  selector: 'app-menu-home',
  templateUrl: './menu-home.component.html',
  styleUrls: ['./menu-home.component.css']
})
export class MenuHomeComponent extends BaseComponent implements OnInit {

  constructor(
    private appService: AppService
  ) {
    super();
  }

  user: SessionUser;

  userRouteMenu = {
    route: '',
    icon: '',
    title: ''
  };

  ngOnInit(): void {
    this.user = this.appService.sessionUser;
    this.handleUserRouteMenu();
    this.appService.sessionUserEvent.pipe(
      takeUntil(this.destroy$)
    ).subscribe(resp => {
      this.user = resp;
      this.handleUserRouteMenu();
    });
  }

  private handleUserRouteMenu() {
    let title = '';
    if (!this.user) {
      this.userRouteMenu = {
        route: '/login',
        icon: 'fal fa-icon fa-sign-in',
        title: 'modules.main.components.menu.signin'
      };
      return;
    }

    if (this.hasThomsonProfile(ThomsonUserProfile.Admin)) {
      this.userRouteMenu = {
        route: '/backoffice',
        icon: 'fal fa-icon fa-building',
        title: 'modules.backoffice.title'
      };
    }
    if (this.hasThomsonProfile(ThomsonUserProfile.Staff)) {
      this.userRouteMenu = {
        route: '/trainings',
        icon: 'fal fa-icon fa-address-card',
        title: 'modules.trainings.title'
      };
    }
    if (this.hasThomsonProfile(ThomsonUserProfile.GuestGed, ThomsonUserProfile.MasterGED) ||
      this.hasPartnerProfile(PartnerUserProfile.GuestGed)) {
      this.userRouteMenu = {
        route: '/ged',
        icon: 'fal fa-icon fa-aindustry',
        title: 'modules.ged.title'
      };
    }

    if (this.hasThomsonProfile(ThomsonUserProfile.GuestProjects, ThomsonUserProfile.MasterProjects) ||
      this.hasPartnerProfile(PartnerUserProfile.Project)) {
      this.userRouteMenu = {
        route: '/projects',
        icon: 'fal fa-icon fa-aindustry',
        title: 'modules.projects.title'
      };
    }


    // this.user.userThomson.profile(profile => {
    //   switch (profile) {
    //     case UserProfile.Admin || UserProfile.Operator:
    //       this.userRouteMenu = {
    //         route: '/backoffice',
    //         icon: 'fal fa-icon fa-building',
    //         title: 'modules.backoffice.title'
    //       };
    //       break;
    //     case UserProfile.Trainings:
    //       this.userRouteMenu = {
    //         route: '/trainings',
    //         icon: 'fal fa-icon fa-address-card',
    //         title: 'modules.trainings.title'
    //       };
    //       break;
    //     case UserProfile.Ged:
    //       this.userRouteMenu = {
    //         route: '/ged',
    //         icon: 'fal fa-icon fa-aindustry',
    //         title: 'modules.ged.title'
    //       };
    //       break;
    //     case UserProfile.Projects:
    //       this.userRouteMenu = {
    //         route: '/projects',
    //         icon: 'fal fa-icon fa-aindustry',
    //         title: 'modules.projects.title'
    //       };
    //       break;
    //   }
    // });
  }

  hasThomsonProfile(...profiles: ThomsonUserProfile[]) {
    for (let p in profiles) {
      if (this.user.userThomson?.profile & profiles[p]) {
        return true;
      }
    }
    return false;
  }
  hasPartnerProfile(...profiles: PartnerUserProfile[]) {
    for (let p in profiles) {
      if (this.user.userPartner?.profile & profiles[p]) {
        return true;
      }
    }
    return false;
  }

}
