import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { finalize, map, takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { AppService } from 'src/app/shared/services/app.service';
import { ProfileService } from 'src/app/shared/services/profile.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {

  constructor(private profileService: ProfileService,
              private appService: AppService) {
    super();
  }
  submited = false;
  currentBackground = '';

  result = {
    message: null,
    success: false,
    errors: null
  };


  form = new FormGroup({
    email: new FormControl('', Validators.compose([
      Validators.required,
      Validators.email
    ]))
  });

  ngOnInit(): void {
    this.rotateBackgroundImages();
  }

  rotateBackgroundImages() {
    var randomNumber = Math.floor(Math.random() * 6) + 1;

    this.currentBackground = 'bg_'+randomNumber+'.jpg';

    setTimeout(() => {
      this.rotateBackgroundImages();
    }, 10000);
  }
  send(){
    this.submited = true;
    if(this.form.invalid) return;
    this.appService.spinner.show();
    this.profileService.resetPasswordRequest(this.form.value).pipe(
      takeWhile(() => this.isAlive),
      map(resp => resp.data),
      finalize(() => this.appService.spinner.hide())
    ).subscribe(resp => this.result = resp);
  }

}
