<div class="login-wrapper" [style]="
    'background-image: url(\'assets/images/' + currentBackground + '\');'
  ">
  <div class="ui middle aligned center aligned grid">
    <div class="column">
      <form class="ui large form">
        <div class="ui stacked segment" [formGroup]="form">
          <div class="field p-l-50 p-r-50 p-t-30 p-b-10">
            <img src="assets/images/ch-logo.png" class="logo" />
          </div>
          <div class="field required p-l-50 p-r-50" [ngClass]="{ error: form.controls.email.invalid && submited }">
            <div class="ui left icon input">
              <i class="mail icon"></i>
              <input type="text" required formControlName="email" [placeholder]="
                  'modules.main.components.login.fields.email' | translate
                " />
            </div>
            <ch-pointing-label *ngIf="submited && form.controls.email?.errors?.required">
              {{
              "modules.main.components.login.validations.email.required"
              | translate
              }}
            </ch-pointing-label>
            <ch-pointing-label class="error" *ngIf="submited && form.controls.email?.errors?.email">
              {{
              "modules.main.components.login.validations.email.invalid"
              | translate
              }}
            </ch-pointing-label>
          </div>
          <div class="field required p-l-50 p-r-50" [ngClass]="{ error: form.controls.password.invalid && submited }">
            <div class="ui left icon input">
              <i class="lock icon"></i>
              <input type="password" required formControlName="password" [placeholder]="
                  'modules.main.components.login.fields.password' | translate
                " />
            </div>
            <ch-pointing-label *ngIf="submited && form.controls.password?.errors?.required">
              {{
              "modules.main.components.login.validations.password.required"
              | translate
              }}
            </ch-pointing-label>
          </div>
          <div class="field p-l-50 p-r-50">
            <button class="ui fluid large thomson submit button" (click)="signIn()">
              {{ "common.buttons.enter" | translate }}
            </button>
          </div>
          <div class="field p-l-50 p-r-50 p-t-30 p-b-5">
            <a [routerLink]="['/reset-password']">{{ 'common.buttons.forgotPassword' | translate }}</a>
          </div>
          <p>
            Caso tenha dúvidas envie email para: <br>
            <a href="mailto:ComunicadoParceirosPGM@thomsonreuters.com">ComunicadoParceirosPGM@thomsonreuters.com</a>
          </p>
        </div>
      </form>
    </div>
  </div>
</div>
