import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/shared/services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class BaseBackofficeHttpService{

  protected apiUrlV1 = `${this.appConfigService.appConfig.apiUrl}/v1/backoffice`;

  constructor(protected appConfigService: AppConfigService){
  }

}
