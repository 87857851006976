<div class="wrapper-banner">
  <img width="50%" src="../../../../assets/images/Capa_do_Portal_Q3.png" />
</div>
<div class="home-header-menu">

  <nav id="menu">
    <ul>
      <!-- <li *ngIf="canProject">
        <div class="menu-item clickable" [routerLink]="['/projects']">
          <img src="assets/icons/circles.svg" height="30">
          <p>{{'modules.projects.title' | translate}}</p>
        </div>
      </li> -->
      <li *ngIf="canGed">
        <div class="menu-item clickable">
          <a [href]="routeTrainingFolderId">
            <img src="assets/icons/escalator.svg" height="30">
            <p>{{'modules.trainings.title' | translate}}</p>
          </a>
        </div>
      </li>
      <li *ngIf="canGed">
        <div class="menu-item clickable">
          <a [href]="routeMktFolderId">
            <img src="assets/icons/megaphone.svg" height="30">
            <p>
              {{'modules.main.components.home.marketing' | translate}}
            </p>
          </a>
        </div>
      </li>
      <li *ngIf="canGed">
        <div class="menu-item clickable">
          <a [href]="routeToolsFolderId">
            <img src="assets/icons/gear.svg" height="30">
            <p>{{'modules.main.components.home.tools' | translate}}</p>
          </a>
        </div>
      </li>
      <li *ngIf="canGed">
        <div class="menu-item clickable">
          <a [href]="routeCorporateSolutionFolderId">
            <img src="assets/icons/laptop.svg" height="30">
            <p>{{'common.corporateSolutions' | translate}}</p>
          </a>
        </div>
      </li>
      <!-- <li *ngIf="canGed">
        <div class="menu-item clickable">
          <a [href]="routeSalesFolderId">
            <img src="assets/icons/money.svg" height="30">
            <p>{{'common.salesPlay' | translate}}</p>
          </a>
        </div>
      </li> -->
      <!-- <li *ngIf="canGed">
        <div class="menu-item clickable">
          <a href="https://renewals-thomsonreuters.force.com/LatAmPartnerCommunity/login" target="_blank">
            <img src="assets/icons/privacy-policy.svg" height="30">
            <p>{{'modules.sfdc.title' | translate}}</p>
          </a>
        </div>
      </li> -->
      <!-- <li *ngIf="canGed">
        <div class="menu-item clickable">
          <a href="https://trpartnerportal.com.br/ged/documents/58aecbcb-e02e-408b-b245-f89b28941918" target="_blank">
            <img src="assets/icons/partners.png" height="30">
            <p>{{ 'Campanha de Vendas Juntos ' | translate}}{{year}}</p>
          </a>
        </div>
      </li> -->
      <!-- <li *ngIf="canCertifications">
        <div class="menu-item clickable" [routerLink]="['/certifications']">
          <img src="assets/icons/replace-folder.svg" height="50">
          <span>{{'modules.certificates.title' | translate}}</span>
        </div>
      </li> -->
    </ul>
  </nav>
</div>

<div class="home-content m-t-40">
  <div class="ui two column centered stackable grid">

    <div class="column">
      <h5 class="m-b-20">IMPORTANTE SABER</h5>
      Sessão para trazer em destaque os temas mais relevantes das últimas semanas, reforçar atualizações ,
      notícias importantes para o nosso relacionamento e nossa operação. Esperamos que seja mais uma ferramenta que
      facilite nossa comunicação.
      <br>
      <br>
      <a [href]="routeFolderId">
        Acessar
        <i class="fal fa-arrow-right"></i>
      </a>

      <h5 class="m-b-20 m-t-30">CALENDÁRIO</h5>
      Em construção...
      <!-- <iframe src="https://calendar.google.com/calendar/embed?src=programa.canais%40gmail.com&ctz=America%2FSao_Paulo" style="border: 0" width="800" height="600" frameborder="0" scrolling="no"></iframe> -->
    </div>

    <div class="column">
      <div class="slideshow-container">
        <h5 class="m-b-20">VÍDEOS</h5>

        <!--<div class="mySlides fade">
          <iframe [src]="'https://www.dropbox.com/s/9dm6sw6s41rhsfb/vdo-tr-campanha-parceiros-28068_5%20(2).mp4?dl=0' | safeUrl" frameborder="0" allowfullscreen>
          </iframe>
        </div>-->

        <div class="mySlides fade">
          <iframe [src]="'https://player.vimeo.com/video/588893450' | safeUrl" frameborder="0" allowfullscreen>
          </iframe>
        </div>

        <div class="mySlides fade">
          <iframe [src]="'https://player.vimeo.com/video/649616767'| safeUrl" frameborder="0" allowfullscreen>
          </iframe>
        </div>

        <div class="mySlides fade">
          <iframe [src]="'https://player.vimeo.com/video/588893792'| safeUrl" frameborder="0" allowfullscreen>
          </iframe>
        </div>

        <div class="mySlides fade">
          <iframe [src]="'https://player.vimeo.com/video/588892437'| safeUrl" frameborder="0" allowfullscreen>
          </iframe>
        </div>

        <div class="mySlides fade">
          <iframe [src]="'https://www.youtube.com/embed/iJmFBRlTudE'| safeUrl" frameborder="0" allowfullscreen>
          </iframe>
        </div>

        <div class="mySlides fade">
          <iframe [src]="'https://www.youtube.com/embed/gbwV8RY9I6k'| safeUrl" frameborder="0" allowfullscreen>
          </iframe>
        </div>

        <div class="mySlides fade">
          <iframe [src]="'https://www.youtube.com/embed/n2wOPWD5NTU' | safeUrl" frameborder="0" allowfullscreen>
          </iframe>
        </div>

        <div class="mySlides fade">
          <iframe [src]="'https://www.youtube.com/embed/qb_ap64W5nE' | safeUrl" frameborder="0" allowfullscreen>
          </iframe>
        </div>

        <div class="mySlides fade">
          <iframe [src]="'https://www.youtube.com/embed/JvB0u-sb_dc' | safeUrl" frameborder="0" allowfullscreen>
          </iframe>
        </div>

        <div class="mySlides fade">
          <iframe [src]="'https://www.youtube.com/embed/py7qwvywmOU' | safeUrl" frameborder="0" allowfullscreen>
          </iframe>
        </div>

        <div class="mySlides fade">
          <iframe [src]="'https://www.youtube.com/embed/jJmwLM60TfE' | safeUrl" frameborder="0" allowfullscreen>
          </iframe>
        </div>

        <div class="mySlides fade">
          <iframe [src]="'https://player.vimeo.com/video/556342070' | safeUrl" frameborder="0" allowfullscreen>
          </iframe>
        </div>

        <div class="mySlides fade">
          <iframe [src]="'https://player.vimeo.com/video/556346050' | safeUrl" frameborder="0" allowfullscreen>
          </iframe>
        </div>

        <!-- Next and previous buttons -->
        <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
        <a class="next" (click)="plusSlides(1)">&#10095;</a>
      </div>
      <br>

      <!-- The dots/circles -->
      <div style="text-align:center">
        <span class="dot" (click)="currentSlide(1)"></span>
        <span class="dot" (click)="currentSlide(2)"></span>
        <span class="dot" (click)="currentSlide(3)"></span>
        <span class="dot" (click)="currentSlide(4)"></span>
        <span class="dot" (click)="currentSlide(5)"></span>
        <span class="dot" (click)="currentSlide(6)"></span>
        <span class="dot" (click)="currentSlide(7)"></span>
        <span class="dot" (click)="currentSlide(8)"></span>
        <span class="dot" (click)="currentSlide(9)"></span>
        <span class="dot" (click)="currentSlide(10)"></span>
        <span class="dot" (click)="currentSlide(11)"></span>
        <span class="dot" (click)="currentSlide(12)"></span>
        <span class="dot" (click)="currentSlide(13)"></span>
        <!--<span class="dot" (click)="currentSlide(14)"></span>-->
      </div>
    </div>

  </div>
</div>





