import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../models/app-config';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor(private http: HttpClient) { }

  configUrl =  environment.production ? `config/app.config.json` : `config/app.config.dev.json`;
  private _appConfig: AppConfig = null;

  get appConfig() {
    return this._appConfig;
  }

  public load(): Promise<AppConfig> {
      return new Promise((resolve, reject) => {
        this.http.get(`${this.configUrl}?v=${Date.now()}`).subscribe((response: AppConfig) => {
            this._appConfig = response;
            resolve(this._appConfig);
      });
    });
  }
}
