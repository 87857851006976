import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent extends BaseComponent implements OnInit {

  constructor(
    private appService: AppService) {
    super();
  }

  ngOnInit(): void {
    const title = this.appService.translateService.instant('modules.shared.components.forbidden.title');
    this.appService.breadcrumbService.emmiter.next({
      icon: 'fal fa-ban fa-icon',
      title,
      path: [title || '']
    });
  }

}
