import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { takeWhile, finalize, map } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { AppService } from 'src/app/shared/services/app.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { CustomValidators } from 'src/app/shared/validators/custom.validators';

@Component({
  selector: 'app-recover-password-with-token',
  templateUrl: './recover-password-with-token.component.html',
  styleUrls: ['./recover-password-with-token.component.css']
})
export class RecoverPasswordWithTokenComponent extends BaseComponent implements OnInit {

  constructor(private actRoute: ActivatedRoute,
    private appService: AppService,
    private profileService: ProfileService) {
    super();
  }
  submited = false;
  currentBackground = '';

  result = {
    message: null,
    success: false,
    errors: null
  };
  form = new FormGroup({
    token: new FormControl('', Validators.required),
    password: new FormControl('', Validators.compose([
      Validators.required,
    ])),
    passwordConfirmation: new FormControl('', Validators.compose([
      Validators.required,
      CustomValidators.match('password')
    ]))
  });

  ngOnInit(): void {
    this.rotateBackgroundImages();
    this.form.get('token').setValue(this.actRoute.snapshot.params.token);
  }
  rotateBackgroundImages() {
    var randomNumber = Math.floor(Math.random() * 6) + 1;

    this.currentBackground = 'bg_'+randomNumber+'.jpg';

    setTimeout(() => {
      this.rotateBackgroundImages();
    }, 10000);
  }
  send(){
    this.submited = true;
    if(this.form.invalid) return;
    this.appService.spinner.show();
    this.profileService.resetPasswordWithToken(this.form.value).pipe(
      takeWhile(() => this.isAlive),
      map(resp => resp.data),
      finalize(() => this.appService.spinner.hide())
    ).subscribe(resp => this.result = resp);
  }

}
