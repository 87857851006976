import { NgModule, ModuleWithProviders, LOCALE_ID } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DateValueAccessor } from './directives/date-value-accessor.directive';
import { NoActionHrefDirective } from './directives/no-action-href.directive';
import { ScrollTopDirective } from './directives/scroll-top.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { RemoveClassOnClickDirective } from './directives/remove-class-on-click-directive.directive';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FormsModule, ReactiveFormsModule, NgModel } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxChameleonModule } from 'ngx-chameleon';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastrModule } from 'ngx-toastr';
import { NgxPagedListModule } from 'ngx-paged-list';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateLoader, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QuillModule } from 'ngx-quill';
import { registerLocaleData } from '@angular/common';
import localePtBR from '@angular/common/locales/pt';
registerLocaleData(localePtBR);
import { BaseComponent } from './components/base/base.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { GridLoadingComponent } from './components/grid-loading/grid-loading.component';
import { GridPaginationComponent } from './components/grid-pagination/grid-pagination.component';
import { MenuComponent } from './components/menu/menu.component';
import { CurrencyMaskDirective } from './directives/currency-mask/currency-mask.directive';
import { RemoveParentElementDirective } from './directives/remove-parent-element.directive';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CNPJPipe } from './pipes/cnpj.pipe';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { AccordionModule } from 'primeng/accordion';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { RegisterUserComponent } from './components/register-user/register-user.component';
import { RegisterCustomerComponent } from './components/register-customer/register-customer.component';
import { ChangePasswordComponent } from './components/profile/change-password/change-password.component';
import { MyProfileComponent } from './components/profile/my-profile/my-profile.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${Date.now()}`);
}

@NgModule({
  declarations: [
    DateValueAccessor,
    NoActionHrefDirective,
    ScrollTopDirective,
    DisableControlDirective,
    RemoveClassOnClickDirective,
    CurrencyMaskDirective,
    RemoveParentElementDirective,

    SafeUrlPipe,
    LimitToPipe,
    FilterPipe,
    CNPJPipe,

    BaseComponent,
    ForbiddenComponent,
    GridLoadingComponent,
    GridPaginationComponent,
    MenuComponent,
    RegisterUserComponent,
    RegisterCustomerComponent,
    ChangePasswordComponent,
    MyProfileComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    EditorModule,
    QuillModule.forRoot(),
    MultiSelectModule,
    NgxChameleonModule,
    NgxSpinnerModule,
    NgSelectModule,
    DropdownModule,
    EditorModule,
    AutoCompleteModule,
    AccordionModule,
    InputNumberModule,
    InputTextModule,
    CalendarModule,
    ToastrModule.forRoot({
      enableHtml: true,
      progressBar: true,
      timeOut: 2500,
      positionClass: 'toast-top-center',
    }),
    NgxMaskModule.forRoot(),
    NgxPagedListModule,
    NgSelectModule,
    HttpClientModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  exports: [
    DateValueAccessor,
    NoActionHrefDirective,
    ScrollTopDirective,
    DisableControlDirective,
    RemoveClassOnClickDirective,
    CurrencyMaskDirective,
    RemoveParentElementDirective,

    SafeUrlPipe,
    LimitToPipe,
    FilterPipe,
    CNPJPipe,

    CommonModule,
    FormsModule,
    AutoCompleteModule,
    ReactiveFormsModule,
    RouterModule,
    NgxChameleonModule,
    MultiSelectModule,
    NgxSpinnerModule,
    ToastrModule,
    NgxPagedListModule,
    DropdownModule,
    NgSelectModule,
    HttpClientModule,
    TranslateModule,
    BaseComponent,
    EditorModule,
    ForbiddenComponent,
    GridLoadingComponent,
    GridPaginationComponent,
    MenuComponent,
    NgSelectModule,
    DropdownModule,
    AccordionModule,
    InputTextModule,
    InputNumberModule,
    CalendarModule,
    EditorModule,
    NgxMaskModule,
    QuillModule,
    RegisterUserComponent,
    RegisterCustomerComponent,
    MyProfileComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        DatePipe,
        NgModel,
        TranslateStore,
        {
          provide: LOCALE_ID,
          useValue: 'pt'
        }
      ]
    };
  }
}
