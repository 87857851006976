import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base',
  template: '<p>base works!</p>'
})
export class BaseComponent implements OnInit, OnDestroy {

  constructor() { }

  destroy$: Subject<boolean> = new Subject<boolean>();

  protected isAlive = true;

  ngOnInit() {
  }

  ngOnDestroy() {
    this.isAlive = false;
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
