enum PartnerUserProfile {
    Master = 1 << 0,
    GuestGed = 1 << 1,
    Project = 1 << 2
  }
const allPartnerProfiles = (): any[] => [
      { value: 'Master', description: 'Master' },
      { value: 'GuestGed', description: 'Guest GED' },
      { value: 'Project', description: 'Project' }
  ];

export {PartnerUserProfile, allPartnerProfiles};
