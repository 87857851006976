enum ThomsonUserProfile {
  Admin = 1 << 0,
  Staff = 1 << 1,
  MasterGED = 1 << 2,
  MasterProjects = 1 << 3,
  GuestGed = 1 << 4,
  GuestProjects = 1 << 5
}
const allThomsonProfiles = (): any[] => [
  { value: 'Admin', description: 'Administrator' },
  { value: 'Staff', description: 'Staff' },
  { value: 'MasterGed', description: 'Master GED' },
  { value: 'MasterProjects', description: 'Master Projects' },
  { value: 'GuestGed', description: 'Guest GED' },
  { value: 'GuestProjects', description: 'Guest Projects' }
];

export {ThomsonUserProfile, allThomsonProfiles};
