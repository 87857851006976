<div ch-grid-row>

  <div ch-grid-column tablet="16" computer="16">
    <div class="ui segment basic center aligned paddingless">
      <span class="fa-10x" style="color: #db2828">4</span>
      <i class="fal fa-frown fa-9x" style="color: #db2828"></i>
      <span class="fa-10x" style="color: #db2828">3</span>
      <h2 class="ui header red text">
        {{ 'modules.shared.components.forbidden.title' | translate }}
      </h2>
    </div>
  </div>
</div>
