import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyProfileComponent } from '../shared/components/profile/my-profile/my-profile.component';
import { allPartnerProfiles, PartnerUserProfile } from '../shared/enums/partner-user-profile';
import { allThomsonProfiles, ThomsonUserProfile } from '../shared/enums/thomson-user-profile';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MenuHomeComponent } from './components/menu-home/menu-home.component';
import { RecoverPasswordWithTokenComponent } from './components/recover-password-with-token/recover-password-with-token.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LoadGuard } from './guards/load.guard';

const routes: Routes = [
  {
    path: '', component: MenuHomeComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home',
       canActivate: [LoadGuard],
       component: HomeComponent},
      { path: 'forbidden', component: ForbiddenComponent }
    ]
  },
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: 'reset-password/:token', component: RecoverPasswordWithTokenComponent},
  { path: 'login', component: LoginComponent },

  {
    path: 'backoffice',
    loadChildren: () => import('../backoffice/backoffice.module').then(m => m.BackofficeModule),
    canLoad: [LoadGuard],
    data: {
      profile: [ThomsonUserProfile.Admin]
    }
  },
  {
    path: 'ged',
    loadChildren: () => import('../ged/ged.module').then(m => m.GedModule),
    canLoad: [LoadGuard],
    data: {
      profile: [ThomsonUserProfile.GuestGed, ThomsonUserProfile.MasterGED,]
    }
  },
  {
    path: 'projects',
    loadChildren: () => import('../projects/projects.module').then(m => m.ProjectsModule),
    canLoad: [LoadGuard],
    data: {
      profile: [ThomsonUserProfile.GuestProjects, ThomsonUserProfile.MasterProjects, PartnerUserProfile.Project]
    }
  },
  {
    path: 'certifications',
    loadChildren: () => import('../certifications/certifications.module').then(m => m.CertificationsModule),
    canLoad: [LoadGuard],
    data: {
      profile: [ThomsonUserProfile.Admin, ThomsonUserProfile.Staff, PartnerUserProfile.Master]
    }
  },
  {
    path: 'trainings',
    loadChildren: () => import('../trainings/trainings.module').then(m => m.TrainingsModule),
    canLoad: [LoadGuard],
    data: {
      profiles: [ThomsonUserProfile.Staff]
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('../reports/reports.module').then(m => m.ReportsModule),
    // canLoad: [LoadGuard], // (problema na rota... REVER) //
    data: {
      profiles: []
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
