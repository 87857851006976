import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormArray, FormGroup, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { TopBarBreadcrumbService, AlertService } from 'ngx-chameleon';
import { SessionUser } from '../models/session-user';
import { ThomsonUserProfile } from 'src/app/shared/enums/thomson-user-profile';
import { PartnerUserProfile } from 'src/app/shared/enums/partner-user-profile';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { PagedListService } from 'ngx-paged-list';
import * as FileSaver from 'file-saver';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private appConfigService: AppConfigService,
    public breadcrumbService: TopBarBreadcrumbService,
    public translateService: TranslateService,
    public alertService: AlertService,
    public topBarBreadcrumb: TopBarBreadcrumbService,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService) {
    this.forms = new CustomFormsService();
    this.toastr = new CustomToastrService(this.toastrService);
    this.spinner = new CustomSpinnerService(this.spinnerService);
    this.dateUtils = new DateUtils();
  }

  forms: CustomFormsService;
  toastr: CustomToastrService;
  spinner: CustomSpinnerService;
  dateUtils: DateUtils;
  currentLanguage: string;
  languages = ['pt', 'en'];
  resp = false;

  // tslint:disable-next-line:variable-name
  private _sessionUser: SessionUser;

  public get sessionUser(): SessionUser {
    if (!this._sessionUser) {
      const jsonUserData = localStorage.getItem(environment.localStore.user);
      this._sessionUser = jsonUserData ? JSON.parse(jsonUserData) : null;
    }
    return this._sessionUser;
  }

  sessionUserEvent = new EventEmitter<SessionUser>();

  //Por enquanto ficará chumbado português
  getCurrentLanguage(): string {
    return 'pt';
    // let lang: string = localStorage.getItem(environment.localStore.lang);

    // if (lang && /(pt)|(en)/i.test(lang)) {
    //   return lang;
    // }

    // lang = this.getBrowserLanguage();

    // if (/(pt-br)|(pt)/i.test(lang)) {
    //   return 'pt';
    // }

    // if (/(en-us)|(en)/i.test(lang)) {
    //   return 'en';
    // }

    // return 'pt';
  }

  getBrowserLanguage() {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return undefined;
    }
    if (window.navigator.languages && navigator.languages.length) {
      return window.navigator.languages[0];
    }
    return window.navigator.language;
  }

  changeLanguage(lang: string) {
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    this.currentLanguage = lang;
    localStorage.setItem(environment.localStore.lang, this.currentLanguage);
  }

  storeUser(sessionUser: SessionUser) {
    this._sessionUser = sessionUser;
    this.sessionUserEvent.next(sessionUser);
    localStorage.setItem(environment.localStore.user, JSON.stringify(sessionUser));
  }

  removeUser() {
    localStorage.removeItem(environment.localStore.user);
    this._sessionUser = null;
    this.sessionUserEvent.next(null);
  }

  get accessToken(): string {
    return localStorage.getItem(environment.localStore.token);
  }

  storeAccessToken = (token: string) => localStorage.setItem(environment.localStore.token, token);

  removeAccessToken = () => localStorage.removeItem(environment.localStore.token);

  handleTransalation(key: string, input: string) {
    this.translateService.get(key).subscribe(resp => input = resp);
  }

  onFileChange($event, formGroup: any) {
    this.toBase64($event.target as HTMLInputElement).pipe().subscribe(resp => {
      if (formGroup && this.verifyExtension(resp.fileName)) {
        formGroup.get('name').setValue(resp.fileName);
        formGroup.get('buffer').setValue(resp.buffer);
        formGroup.get('contentType').setValue(resp.contentType);
      }
    });
  }

  private toBase64(file: HTMLInputElement): Observable<any> {
    const reader = new FileReader();
    const fileName = file.files[0].name;
    const contentType = file.files[0].type;

    reader.readAsBinaryString(file.files[0]);
    reader.onload = () => console.log(btoa(reader.result as string));

    return Observable.create(observer => {
      reader.onload = () => {
        observer.next({
          fileName,
          buffer: btoa(reader.result as string),
          contentType
        });
      }
      reader.onerror = error => observer.error(error);
    });
  }

  //Adequacao ao componente de Upload do Primefaces
  onFileChangePrimeNG($event, formGroup: any) {
    this.toBase64PrimeNG($event as HTMLInputElement).pipe().subscribe(resp => {
      if (formGroup) {

        formGroup.get('fileName').setValue(resp.fileName);
        formGroup.get('buffer').setValue(resp.buffer);
        formGroup.get('contentType').setValue(resp.contentType);

      }
    });
  }

  public toBase64PrimeNG(file: HTMLInputElement): Observable<any> {
    const reader = new FileReader();
    const fileName = file[0].name;
    const contentType = file[0].type;

    reader.readAsBinaryString(file[0]);
    reader.onload = () => console.log(btoa(reader.result as string));

    return new Observable((observer => {
      reader.onload = () => {
        observer.next({
          fileName,
          buffer: btoa(reader.result as string),
          contentType
        });
      }
      reader.onerror = error => observer.error(error);
    }));
  }

  private verifyExtension(fileName: string) {
    const permitedExtensions = ['jpg', 'png', 'jpeg'];
    const extension = fileName.toLowerCase().split('.').pop();

    if (typeof permitedExtensions.find((ext) => extension === ext) === 'undefined') {
      this.toastr.error(`Extensão ${extension} não permitida`);
      return false;
    }
    else { return true; }
  }

  // Converte Blob para base64 string (Upload de arquivo particionado no AWS S3)
  processFile = async (file: Blob): Promise<string> => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (fileEvent) => {
        resolve(btoa(reader.result as string))
      }
      reader.onerror = () => {
        reject('error')
      }
      reader.readAsBinaryString(file)
    })
  }

  //Converte data (filtro) para parametros a serem enviados via querystring (endpont Get)
  public createHttpParams(params: {}): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    Object.keys(params).forEach(param => {
      if (params[param]) {
        httpParams = httpParams.set(param, params[param]);
      }
    });

    return httpParams;
  }

  order(array: any[], prop: string) {
    const aux = [...array];
    return array.sort((a, b) => {
      let x = a[prop];
      let y = b[prop];

      if (typeof x === 'string') {
        x = ('' + x).toLowerCase();
      }
      if (typeof y === 'string') {
        y = ('' + y).toLowerCase();
      }

      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  // validateProfile(profiles: UserProfile[]): boolean {
  //   if (this.sessionUser == null) { return false; }
  //   this.sessionUser.profiles.forEach(profile => {
  //     return profiles.indexOf(profile) >= 0;
  //   });
  // }

  copyToClipboard(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  exportExcel(array: any[], fileName: string) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(array);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, fileName);
    });
  };

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  randomId = () => `${Math.random().toString(36).substring(2)}`;

  newUuid = () => {
    const d0 = Math.random() * 0xffffffff | 0;
    const d1 = Math.random() * 0xffffffff | 0;
    const d2 = Math.random() * 0xffffffff | 0;
    const d3 = Math.random() * 0xffffffff | 0;
    const lut = []; for (let i = 0; i < 256; i++) { lut[i] = (i < 16 ? '0' : '') + (i).toString(16); }
    return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + '-' +
      lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + '-' + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + '-' +
      lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + '-' + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
      lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
  }

  imgUrlNoCache(src?: string) {
    if (!src) { return null; }
    return `${src}?random=${this.randomId()}`;
  }

  storeFilterGrid(key: string, grid: PagedListService, data?: any) {
    const filter = {
      ...data || {},
      pageIndex: grid.pageIndex,
      sortField: grid.sortField,
      sortType: grid.sortType
    };
    localStorage.setItem(key, JSON.stringify(filter));
  }

  retrieveFilterGrid(key: string, form?: FormGroup) {
    const serializedFilter = localStorage.getItem(key);
    const filter = serializedFilter != null ? JSON.parse(serializedFilter) : null;
    if (form && filter) form.patchValue(filter);
    return {
      ...filter || {},
      pageIndex: filter?.pageIndex ? parseInt(filter.pageIndex) : 1
    };
  }

  removeFilterGrid(key: string) {
    localStorage.removeItem(key);
  }

  // P E R M I S S I O N A M E N T O S
  canBackoffice(user: SessionUser) {

    if (user.userThomson) {
      this.resp = this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.Admin)
        || this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.Staff);
    }
    else {
      this.resp = false;
    }

    return this.resp;
  };

  canProject(user: SessionUser) {

    if (user.userThomson) {
      this.resp = this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.Admin)
        || this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.Staff)
        || this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.MasterProjects)
        || this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.GuestProjects);
    }

    if (user.userPartner) {
      this.resp = this.hasPartnerProfile(user.userPartner, PartnerUserProfile.Master)
        || this.hasPartnerProfile(user.userPartner, PartnerUserProfile.Project);
    }

    return this.resp;
  };

  canReport(user: SessionUser) {

    if (user.userThomson) {
      this.resp = this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.Admin)
        || this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.Staff);
    }

    if (user.userPartner) {
      this.resp = this.hasPartnerProfile(user.userPartner, PartnerUserProfile.Master);
    }

    return this.resp;
  };

  canCertifications(user: SessionUser) {

    if (user.userThomson) {
      this.resp = this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.Admin)
        || this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.Staff)
        || this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.MasterGED);
    }

    if (user.userPartner) {
      this.resp = this.hasPartnerProfile(user.userPartner, PartnerUserProfile.Master);
    }

    return this.resp;
  };

  canGed(user: SessionUser) {

    if (user.userThomson) {
      this.resp = this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.Admin)
        || this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.Staff)
        || this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.MasterGED)
        || this.hasThomsonProfile(user.userThomson, ThomsonUserProfile.GuestGed);
    }

    if (user.userPartner) {
      this.resp = this.hasPartnerProfile(user.userPartner, PartnerUserProfile.Master)
        || this.hasPartnerProfile(user.userPartner, PartnerUserProfile.GuestGed);
    }

    return this.resp;
  };

  hasThomsonProfile(userT, ...profiles: ThomsonUserProfile[]) {
    for (let p in profiles) {
      if (userT.profile & profiles[p]) {
        return true;
      }
    }
    return false;
  }

  hasPartnerProfile(userT, ...profiles: PartnerUserProfile[]) {
    for (let p in profiles) {
      if (userT.profile & profiles[p]) {
        return true;
      }
    }
    return false;
  }

}

class CustomToastrService {

  constructor(private toastrService: ToastrService) {

  }

  private readonly defaultTitle: string = 'Atenção';

  success = (message: string, title?: string) => setTimeout(() => this.toastrService.success(message, title || this.defaultTitle));

  error = (message: string, title?: string) => setTimeout(() => this.toastrService.error(message, title || this.defaultTitle));

  info = (message: string, title?: string) => setTimeout(() => this.toastrService.info(message, title || this.defaultTitle));

  warning = (message: string, title?: string) => setTimeout(() => this.toastrService.warning(message, title || this.defaultTitle));
}

class CustomSpinnerService {
  constructor(private spinnerService: NgxSpinnerService) {
  }

  show = () => this.spinnerService.show();

  hide = () => this.spinnerService.hide();

  start = () => this.show();

  stop = () => this.hide();
}

class DateUtils {
  toDate(value?: any) {
    if (!value) {
      return null;
    }
    let dateValue: Date;
    if (!(value instanceof Date)) {
      dateValue = new Date(value);
    }
    return dateValue;
  }

  getUTCJsonData(value?: any) {
    if (!value || value == null) {
      return null;
    }
    const date: Date = (value instanceof Date) ? value : this.toDate(value);
    const utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return utc.toJSON();
  }

  getJsonData(value?: any): string {
    if (!value) {
      return null;
    }
    const date: Date = value == null ? new Date() : (value instanceof Date) ? value : this.toDate(value);
    return date.toJSON();
  }

  toDateStr(value?: any): string {
    if (!value || value == null) { return null; }
    let date: Date = (value instanceof Date) ? value : this.toDate(value);
    return date.toISOString().split('T')[0];
  }

}

class CustomFormsService {

  date: DateUtils = new DateUtils();

  clearFormArray(formArray: FormArray) {
    while (formArray.controls.length > 0) {
      formArray.removeAt(0);
    }
  }

  fill(form: FormGroup, data: any) {
    for (const key in form.controls) {
      if (form.controls[key] instanceof FormArray) {
        continue;
      }
      if (form.controls[key] instanceof FormGroup) {
        this.fill(form.controls[key] as FormGroup, data[key]);
      }
      else {
        form.controls[key].setValue(data == null ? null : data[key] == null ? null : data[key]);
      }
    }
  }

  clear(form: FormGroup) {
    for (const key in form.controls) {
      if (form.controls[key]) {
        if (form.controls[key] instanceof FormArray) {
          this.clearFormArray(form.controls[key] as FormArray);
          continue;
        }
        if (form.controls[key] instanceof FormGroup) {
          this.clear(form.controls[key] as FormGroup);
        }
        else {
          form.controls[key].setValue(null);
        }
      }
    }
  }

  toJsonDate(form: FormGroup, ...props: string[]) {
    for (const p in props) {
      if (props[p]) {
        this.controlValueToJsonDate(form.controls[props[p]]);
      }
    }
  }

  controlValueToJsonDate(control: AbstractControl) {
    control.setValue(this.date.getUTCJsonData(control.value));
  }

  removeValidator(control: AbstractControl) {
    control.setValue('');
    control.clearValidators();
    control.setErrors(null);
    control.setValidators(null);
    control.updateValueAndValidity();
  }

  addValidators(control: AbstractControl, ...validators: any) {
    control.setValidators(validators);
    control.updateValueAndValidity();
  }
}
