import { Component, ChangeDetectionStrategy, ViewChild, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { AppService } from '../../services/app.service';
import { SessionUser } from '../../models/session-user';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { AppConfigService } from '../../services/app-config.service';
import { AppConfig } from '../../models/app-config';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent extends BaseComponent implements OnInit {

  constructor(
    private appConfigService: AppConfigService,
    private router: Router,
    private profileService: ProfileService,
    private appService: AppService) {
    super();
  }

  get avatar(): string {
    return this.user && this.user.avatarUrl ? this.user.avatarUrl : 'assets/images/avatar.png';
  }


  appConfig: AppConfig = this.appConfigService.appConfig;
  canBackoffice = false;
  canReport = false;
  canProject = false;
  canGed = false;
  canCertifications = false;

  user: SessionUser;
  routeMktFolderId: string;
  routeTrainingFolderId: string;
  routeToolsFolderId: string;
  routeChannelFolderId: string;
  routeCorporateSolutionFolderId : string;
  routeSalesFolderId : string;
  partner;
  ngOnInit() {
    this.user = this.appService.sessionUser;

    this.canBackoffice = this.appService.canBackoffice(this.user);
    this.canReport = this.appService.canReport(this.user);
    this.canProject = this.appService.canProject(this.user);
    this.canGed = this.appService.canGed(this.user);
    this.canCertifications = this.appService.canCertifications(this.user);

    this.routeMktFolderId = '/ged/documents/' + this.appConfig.marketingFolderId;
    this.routeToolsFolderId = '/ged/documents/' + this.appConfig.toolsFolderId;
    this.routeChannelFolderId = '/ged/documents/' + this.appConfig.channelsFolderId;
    this.routeCorporateSolutionFolderId = '/ged/documents/' + this.appConfig.corporateSolutionFolderId;
    this.routeSalesFolderId = '/ged/documents/' + this.appConfig.salesPlayFolderId;
    this.routeTrainingFolderId = '/ged/documents/' + this.appConfig.trainigFolderId;

    this.appService.sessionUserEvent.pipe(
      takeUntil(this.destroy$)
    ).subscribe(user => this.user = user);

    this.profileService.get().pipe(
      takeUntil(this.destroy$)
    ).subscribe((resp)=>{
      this.user = resp.data;
    });
  }
  signout() {
    this.canBackoffice = false;
    this.canProject = false;
    this.canReport = false;
    this.canGed = false;
    this.appService.removeAccessToken();
    this.appService.removeUser();
    this.router.navigate(['/login']);
  }
  navigateToGuide(){

    this.router.navigate(['assets/files/Manual_Usuario_TR_PartnerPortal_v1.0.pdf']);
  }
  myProfile() {
    this.router.navigate(['backoffice/my-profile']);
  }
  isPartner(): boolean{

    return this.partner = this.user.userPartner !== undefined;
  }

}
