import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { AppConfigService } from '../shared/services/app-config.service';
import { AppService } from '../shared/services/app.service';

@Component({
  selector: 'main-root',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent extends BaseComponent {


  constructor(
    private appConfig: AppConfigService,
    private appService: AppService
    ) {
    super();
    const config = appConfig.appConfig;
    this.appService.changeLanguage(this.appService.getCurrentLanguage());
  }

}
