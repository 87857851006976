import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PartnerUserProfile } from 'src/app/shared/enums/partner-user-profile';
import { ThomsonUserProfile } from 'src/app/shared/enums/thomson-user-profile';
import { AppService } from 'src/app/shared/services/app.service';


@Injectable({
  providedIn: 'root'
})
export class LoadGuard implements CanLoad, CanActivate {

  constructor(
    private router: Router,
    private appServie: AppService) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
   boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const user = this.appServie.sessionUser;
    if (!user) {
      this.router.navigate(['/login']);
      return of(false);
    }

    return true;

  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {

    const profiles = route.data?.profile as ThomsonUserProfile[];

    const user = this.appServie.sessionUser;
    if (!user) {
      this.router.navigate(['/login']);
      return of(false);
    }

    for (let profile of profiles) {
      if (profiles.indexOf(profile) < 0) {
        this.router.navigate(['/forbidden']);
        return of(false);
      }
    }
    const partnerProfiles =  route.data?.profile as PartnerUserProfile[];
    for (let profile of partnerProfiles) {
      if (partnerProfiles.indexOf(profile) < 0) {
        this.router.navigate(['/forbidden']);
        return of(false);
      }
    }

    return of(true);
  }

}
