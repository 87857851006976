import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/shared/services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private urls = {
    v1: `${this.appConfigService.appConfig.apiUrl}/v1/auth`
  };

  constructor(
    protected appConfigService: AppConfigService,
    private http: HttpClient) { }

  signin = (data: any): Observable<any> => this.http.post(this.urls.v1, data).pipe(map(resp => resp));
}
