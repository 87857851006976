import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseBackofficeHttpService } from 'src/app/backoffice/services/base-backoffice-http.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseBackofficeHttpService {

  url = '';

  constructor(
    protected appConfigService: AppConfigService,
    private http: HttpClient) {
    super(appConfigService);
    this.url = `${this.apiUrlV1}/profiles`;
  }
  resetPasswordRequest = (data: any): Observable<any> => this.http.post<any>(`${this.url}/reset-password-request`, data).pipe(map(resp => resp));
  resetPasswordWithToken = (data: any): Observable<any> => this.http.post<any>(`${this.url}/reset-password-with-token`, data).pipe(map(resp => resp));
  get = (): Observable<any> => this.http.get<any>(`${this.url}`).pipe(map(resp => resp));
  update = (data: any): Observable<any> => this.http.put<any>(`${this.url}/update`, data).pipe(map(resp => resp));
  changePassword = (data: any): Observable<any> => this.http.post<any>(`${this.url}/change-password`, data).pipe(map(resp => resp));

}
